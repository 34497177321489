import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../variables";
import { getToken } from '../authService';
import Loader from "./Loader";
import { FaEdit, FaTrash, FaStar } from 'react-icons/fa';
import toast from 'react-hot-toast';

const ReviewList = ({ onEdit }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/reviews/getAllReviews`, {
        headers: {
          'Authorization': ` ${token}`,
        },
      });
      setReviews(response.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      toast.error("Failed to fetch reviews");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (reviewId) => {
    if (!reviewId) {
      toast.error("Invalid review ID");
      return;
    }

    if (window.confirm('Are you sure you want to delete this review?')) {
      try {
        const token = getToken();
        const formData = new FormData();
        formData.append('id', reviewId);

        const response = await axios.post(`${API_URL}/reviews/deleteReview`, formData, {
          headers: {
            'Authorization': `${token}`,
          },
        });

        if (response.data.status === 'success') {
          toast.success(response.data.message);
          fetchReviews();
        }
      } catch (error) {
        console.error("Error deleting review:", error);
        toast.error("Failed to delete review");
      }
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={index < rating ? "text-yellow-400" : "text-gray-300"}
        size={14}
      />
    ));
  };

  return (
    <div className="space-y-4">
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Title</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Rating</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Comment</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {reviews.map((review) => (
                <tr key={review.id} className="hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm">{review.names}</td>
                  <td className="px-4 py-2 text-sm">{review.title}</td>
                  <td className="px-4 py-2">
                    <div className="flex">
                      {renderStars(review.rating)}
                    </div>
                  </td>
                  <td className="px-4 py-2 text-sm">
                    <div className="max-w-xs truncate">
                      {review.comment}
                    </div>
                  </td>
                  <td className="px-4 py-2 text-sm">
                    {new Date(review.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => onEdit(review)}
                        className="text-blue-600 hover:text-blue-900"
                        title="Edit review"
                      >
                        <FaEdit size={14} />
                      </button>
                      <button
                        onClick={() => handleDelete(review.id)}
                        className="text-red-600 hover:text-red-900"
                        title="Delete review"
                      >
                        <FaTrash size={14} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {reviews.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No reviews found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewList; 