import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { getToken } from '../authService';

function AddReferral({ onClose, initialData }) {
  const [formData, setFormData] = useState(initialData || {
    code: '',
    url: 'https://songstarktours.com?r=',
    id: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'code') {
      setFormData({
        ...formData,
        code: value,
        url: 'https://songstarktours.com?r=' + value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      const form = new FormData();
      form.append('code', formData.code);
      form.append('url', formData.url);
      
      if (initialData?.id) {
        form.append('id', formData.id);
        await axios.post(`${API_URL}/referrals/updateReferral`, form, {
          headers: { 
            "Authorization": `${token}`,
            "Content-Type": "multipart/form-data"
          }
        });
      } else {
        await axios.post(`${API_URL}/referrals/createReferral`, form, {
          headers: { 
            "Authorization": `${token}`,
            "Content-Type": "multipart/form-data"
          }
        });
      }
      onClose();
      window.location.reload();
    } catch (error) {
      console.error('Error saving referral:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="p-4 bg-white rounded-lg max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-green-800">Add Referral</h2>
          <button 
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Code</label>
            <input
              type="text"
              name="code"
              value={formData.code}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">URL</label>
            <input
              type="url"
              name="url"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="bg-green-800 text-yellow-300 px-4 py-2 rounded hover:bg-green-600"
            >
              Add Referral
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddReferral;
