import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import Loader from '../components/Loader';
import { FaCheck, FaTimes, FaFilter, FaCalendarAlt } from 'react-icons/fa';
import { getToken } from '../authService'; 

const api_url = `${API_URL}/custom_reservations/getAllReservations`;
const activities_url = `${API_URL}/activity/getAllActivities`;
const referrals_url = `${API_URL}/referrals/getAllReferrals`;

const PlannedTrips = ({ setPendingCount }) => {
  const [reservations, setReservations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [referralFilter, setReferralFilter] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken(); 
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}` 
          }
        };
        const [reservationsResponse, activitiesResponse, referralsResponse] = await Promise.all([
          axios.get(api_url, headers),
          axios.get(activities_url, headers),
          axios.get(referrals_url, headers)
        ]);

        setReservations(reservationsResponse.data);
        setActivities(activitiesResponse.data);
        setReferrals(referralsResponse.data);
        setPendingCount(reservationsResponse.data.length);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false); 
      }
    };
    fetchData();
  }, [setPendingCount]);

  const getActivityNames = (activityIds) => {
    if (!activityIds || !activities.length) return 'None';

    let parsedActivityIds;
    try {
      parsedActivityIds = JSON.parse(activityIds);
    } catch {
      return 'Error';
    }

    return Array.isArray(parsedActivityIds)
      ? parsedActivityIds
          .map(id => activities.find(act => act.activity_id === id)?.activity_name || 'Unknown')
          .join(', ')
      : 'None';
  };

  const getReferralName = (referralId) => {
    const referral = referrals.find(ref => ref.id === referralId);
    return referral ? referral.code : 'Direct';
  };

  const handleAccept = (reservationEmail) => {
    // Open mail client with the reservation email
    window.location.href = `mailto:${reservationEmail}`;
  };

  const handleReject = async (reservationId) => {
    try {
      const token = getToken(); 
      await axios.post(`${API_URL}/custom_reservations/deleteReservation`, {
        reservation_id: reservationId,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}` 
        }
      });
      setReservations(prev => prev.filter(res => res.id !== reservationId));
    } catch (error) {
      console.error('Error rejecting reservation:', error.message);
    }
  };

  const filteredReservations = reservations.filter(reservation => {
    const reservationDate = new Date(reservation.reservation_date);
    const matchesDateRange = (!startDate || reservationDate >= new Date(startDate)) && 
                           (!endDate || reservationDate <= new Date(endDate));
    
    const matchesReferral = !referralFilter || 
      getReferralName(reservation.referral_id).toLowerCase() === referralFilter.toLowerCase();
    
    return matchesDateRange && matchesReferral;
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-green-800">Custom Reservations</h1>
        
        {/* Filter Controls */}
        <div className="flex items-center gap-4 bg-white p-3 rounded-lg shadow-sm">
          <div className="flex items-center gap-2">
            <FaCalendarAlt className="text-green-800" />
            <div className="flex items-center gap-2">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
                placeholder="Start Date"
              />
              <span className="text-gray-500">to</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
                placeholder="End Date"
              />
            </div>
          </div>
          
          <div className="flex items-center gap-2">
            <FaFilter className="text-green-800" />
            <select
              value={referralFilter}
              onChange={(e) => setReferralFilter(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
            >
              <option value="">All Referrals</option>
              <option value="Direct">Direct</option>
              {referrals.map(referral => (
                <option key={referral.id} value={referral.code}>
                  {referral.code}
                </option>
              ))}
            </select>
          </div>

          {(startDate || endDate || referralFilter) && (
            <button
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setReferralFilter('');
              }}
              className="text-sm text-green-600 hover:text-green-800 px-2 py-1 rounded hover:bg-gray-100"
            >
              Clear Filters
            </button>
          )}
        </div>
      </div>

      <div className="shadow-md rounded-lg">
        <table className="min-w-full bg-white border border-gray-300">
          <thead className="bg-green-800 text-white">
            <tr className="text-sm">
              <th className="px-3 py-2 text-left">No</th>
              <th className="border border-gray-300 p-2">First Name</th>
              <th className="border border-gray-300 p-2">Last Name</th>
              <th className="border border-gray-300 p-2">Email</th>
              <th className="border border-gray-300 p-2">Application Date</th>
              <th className="border border-gray-300 p-2">Phone Number</th>
              <th className="border border-gray-300 p-2">Referral</th>
              <th className="border border-gray-300 p-2">Travelers</th>
              <th className="border border-gray-300 p-2">Message</th>
              <th className="border border-gray-300 p-2">Interests</th>
              <th className="border border-gray-300 p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredReservations.length > 0 ? (
              filteredReservations.map((reservation, index) => (
                <tr key={reservation.id} className="text-sm border-t border-gray-300">
                  <td className="px-3 py-2">{index + 1}</td>
                  <td className="p-2">{reservation.reservation_first_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_last_name || 'None'}</td>
                  <td className="p-2">{reservation.reservation_email || 'None'}</td>
                  <td className="p-2">{reservation.reservation_date || 'None'}</td>
                  <td className="p-2">{reservation.reservation_phone || 'None'}</td>
                  <td className="p-2">{getReferralName(reservation.referral_id)}</td>
                  <td className="p-2">{reservation.people || 'None'}</td>
                  <td className="p-2">{reservation.reservation_message || 'None'}</td>
                  <td className="p-2">{getActivityNames(reservation.activity_ids)}</td>
                  <td className="px-3 py-2 flex justify-between">
                    {reservation.reservation_status === 'accepted' || reservation.reservation_status === 'rejected' ? (
                      <span className={`font-bold ${reservation.reservation_status === 'accepted' ? 'text-green-600' : 'text-red-600'}`}>
                        {reservation.reservation_status.charAt(0).toUpperCase() + reservation.reservation_status.slice(1)}
                      </span>
                    ) : (
                      <>
                        <div className="relative group inline-block">
                          <button className="text-green-600 hover:text-green-500" onClick={() => handleAccept(reservation.reservation_email)}>
                            <FaCheck />
                          </button>
                          <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-green-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                            Accept
                          </span>
                        </div>
                        <div className="relative group inline-block">
                          <button className="text-gray-600 hover:text-red-500" onClick={() => handleReject(reservation.reservation_id)}>
                            <FaTimes />
                          </button>
                          <span className="absolute bottom-4 mb-2 left-2 transform -translate-x-1/2 whitespace-no-wrap bg-red-600 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                            Reject
                          </span>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11" className="text-center p-2 border-t border-gray-300 text-sm">
                  No trips available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlannedTrips;
