import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRCodeComponent = ({ referral }) => {
  if (!referral) return <p>Loading QR Code...</p>;

  return (
    <div className="qr-code-container">
      <h3 className="text-lg font-bold mb-8">Scan to visit our website</h3>
      <QRCodeCanvas value={referral.url} />
      <p className="text-green-800 text-sm font-bold mt-8">Referral Code: {referral.code}</p>
    </div>
  );
};

export default QRCodeComponent;
