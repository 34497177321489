import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../variables";
import { getToken } from '../authService';  
import TourCard from './TourCard';
import Loader from "./Loader";

const api_url = `${API_URL}/package/getAllPackages`;

const TourList = ({ onEdit, onDelete }) => {
  const [tours, setTours] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const token = getToken();
        const response = await axios.get(api_url, {
          headers: {
            'Authorization': `Bearer ${token}`,  
            'Content-Type': 'application/json'
          },
        });

        const validTours = response.data.filter((tour) => tour.package_title && tour.package_location && tour.package_price)
          .map((tour) => ({
            package_id: tour.package_id,
            package_title: tour.package_title,
            package_image: tour.package_image,
            package_summary: tour.package_summary,
            package_location: tour.package_location,
            package_country: tour.package_country,
            package_price: tour.package_price,
            package_days: tour.package_days,
            rates: tour.rates,
            rate_dates: tour.rate_dates,
            best_price_guarantee: tour.best_price_guarantee,
            free_cancelation: tour.free_cancelation,
            is_popular: tour.is_popular,
            package_special: tour.package_special || "N/A",
          }));

        setTours(validTours);
      } catch (error) {
        console.error("Error fetching tour packages:", error.response ? error.response.data : error.message);
      } finally { 
        setLoading(false);
    }
    };

    fetchTours();
  }, []);

  const handleEdit = (tour) => {
    navigate(`/edit-tour/${tour.package_id}`);
  };

  const handleDetailsClick = (tourId) => {
    navigate(`/tour-details/${tourId}`);
  };

  return (
    <div className="space-y-4">
      {loading ? (
        <Loader />  
      ) : (
        tours.length > 0 ? (
          tours.map((tour) => (
            <TourCard 
              key={tour.package_id}  
              tour={tour}  
              onDelete={onDelete}  
              onEdit={handleEdit}  
              onDetailsClick={() => handleDetailsClick(tour.package_id)}  
            />
          ))
        ) : (
          <p>No tours available</p>
        )
      )}
    </div>
  );
};

export default TourList;
