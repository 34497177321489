import React from 'react';
import OrganizationSettings from '../components/Organization';

const SettingsPage = () => {
  return (
    <div className="min-h-screen ">
      <div className="max-w-3xl  px-4">
          <section>
            <OrganizationSettings />
          </section>
        </div>
      </div>
  );
};

export default SettingsPage; 