import React from 'react';
import ReservedTickets from '../components/ReservedTickets';

const ReservedTicketsPage = () => {
  return (
    <div className="flex justify-center p-6 min-h-screen bg-gray-50">
      <main className="min-w-full bg-white border border-gray-200">
        <h1 className="text-xl font-bold text-green-800 mb-6 text-center">
          Reserved Tickets
        </h1>
        <div className="bg-white rounded-lg p-4 mb-4">
          <ReservedTickets />
        </div>
      </main>
    </div>
  );
};

export default ReservedTicketsPage;
