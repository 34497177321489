import React, { useState } from 'react';
import ReviewList from '../components/ReviewList';
import ReviewForm from '../components/ReviewForm';

const ReviewsPage = () => {
  const [selectedReview, setSelectedReview] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false);

  const handleAddNew = () => {
    setSelectedReview(null);
    setFormVisible(true);
  };

  const handleEdit = (review) => {
    setSelectedReview(review);
    setFormVisible(true);
  };

  const handleFormSave = () => {
    setFormVisible(false);
    // Refresh the review list
  };

  const handleFormCancel = () => {
    setFormVisible(false);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Reviews</h1>
      {isFormVisible ? (
        <ReviewForm
          review={selectedReview}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
        />
      ) : (
        <>
          <button
            onClick={handleAddNew}
            className="mb-4 px-4 py-2 bg-green-700 text-white rounded-md hover:bg-green-600"
          >
            Add New Review
          </button>
          <ReviewList onEdit={handleEdit} />
        </>
      )}
    </div>
  );
};

export default ReviewsPage; 