import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useImage } from './ImageContext';
import AddDestination from './AddDestination';
import { FaTrashAlt, FaEdit, FaPlus } from 'react-icons/fa';
import EditDestination from './EditDestination';

const api_url = `${API_URL}/destination/getAllDestinations`;

function Destinations() {
  const [destinations, setDestinations] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const { getImageUrl } = useImage();
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDestination, setCurrentDestination] = useState(null);

  const fetchDestinations = async () => {
    try {
      const token = getToken();
      const response = await axios.get(api_url, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      console.log('Fetched Destinations:', response.data);
      console.log(response.data);
      setDestinations(response.data); 
    } catch (error) {
      console.error('Error fetching destinations:', error);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleAddDestination = (newDestination) => {
    setDestinations([...destinations, newDestination]);
    setShowAddModal(false);
  };

  const handleUpdateDestination = (updatedDestination) => {
    setDestinations(
      destinations.map((destination) =>
        destination.destination_id === updatedDestination.destination_id ? updatedDestination : destination
      )
    );
    setShowEditModal(false);
  };

  const handleDeleteDestination = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.post(
        `${API_URL}/destination/deleteDestination`,
        { destination_id: id },
        {
          headers: {
            Authorization: `${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        setDestinations(destinations.filter((destination) => destination.destination_id !== id));
      } else {
        throw new Error('Failed to delete destination');
      }
    } catch (error) {
      console.error('Error deleting destination:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div className="p-6">
      <div className="mb-6 flex justify-end">
        <button 
          onClick={() => setShowAddModal(true)} 
          className="flex items-center gap-2 bg-green-800 text-yellow-300 px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          <FaPlus size={14} className="text-yellow-300" />
          Add Destination
        </button>
      </div>

      {destinations.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No destinations added.</p>
      ) : (
        <ul className="space-y-4">
          {destinations.map((destination) => (
            <li key={destination.destination_id} 
                className="flex items-center space-x-4 border-b border-gray-100 py-4 last:border-0">
              {destination.destination_image ? (
                <img
                  src={getImageUrl(destination.destination_image)}
                  alt={destination.destination_name}
                  className="w-28 h-28 object-cover rounded-lg"
                />
              ) : (
                <div className="w-28 h-28 bg-gray-100 flex items-center justify-center rounded-lg">
                  <span className="text-gray-400">No Image</span>
                </div>
              )}
              <div className="flex-grow">
                <div className="font-semibold text-gray-800">{destination.destination_name}</div>
                <div className="text-sm text-gray-500">ID: {destination.destination_id}</div>
              </div>
              <div className="flex space-x-3">
                <button
                  onClick={() => {
                    setCurrentDestination(destination);
                    setShowEditModal(true);
                  }}
                  className="text-green-700 hover:text-green-800 transition-colors duration-200"
                >
                  <FaEdit size={16} />
                </button>
                <button 
                  onClick={() => handleDeleteDestination(destination.destination_id)} 
                  className="text-yellow-300 hover:text-red-500 transition-colors duration-200"
                >
                  <FaTrashAlt size={16} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {showAddModal && (
        <AddDestination 
          onClose={() => setShowAddModal(false)} 
          onAddDestination={handleAddDestination} 
        />
      )}

      {showEditModal && currentDestination && (
        <EditDestination
          destination={currentDestination}
          onClose={() => setShowEditModal(false)}
          onUpdateDestination={handleUpdateDestination}
        />
      )}
    </div>
  );
}

export default Destinations;
