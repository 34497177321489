import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { useParams } from 'react-router-dom';
import { useImage } from './ImageContext';
import Loader from './Loader';
import { getToken } from '../authService';

const api_url = `${API_URL}/package/getAllPackages`;

const TourDetails = () => {
  const { packageId } = useParams();
  const [tourDetails, setTourDetails] = useState(null);
  const [destinations, setDestinations] = useState([]);
  const [error, setError] = useState('');
  const { getImageUrl } = useImage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = getToken();
        const [tourResponse, destinationsResponse] = await Promise.all([
          axios.get(api_url),
          axios.get(`${API_URL}/destination/getAllDestinations`, {
            headers: { 'Authorization': `${token}` },
          })
        ]);

        const tours = tourResponse.data;
        const tour = tours.find(tour => tour.package_id === packageId);
        
        if (tour) {
          setTourDetails(tour);
        } else {
          setError('Tour not found.');
        }

        setDestinations(destinationsResponse.data);
      } catch (err) {
        setError('Failed to load tour details.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [packageId]);

  const getDestinationName = (destinationIds) => {
    if (!Array.isArray(destinationIds) || !destinationIds.length) return 'Unknown Destination';
    
    return destinationIds.map(destId => {
      const destination = destinations.find(d => d.destination_id === destId);
      return destination ? destination.destination_name : 'Unknown Destination';
    }).join(', ');
  };

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (loading) {
    return <Loader />;
  }
  return (
      <div className="max-w mx-auto p-6 bg-white shadow-lg rounded-lg space-y-8">
        <h1 className="text-3xl font-bold text-green-700">{tourDetails.package_title}</h1>

        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-green-700">Summary</h2>
          <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: tourDetails.package_summary }}></p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Main Image</h2>
            <img
              src={getImageUrl(tourDetails.package_image)}
              alt={`Main of ${tourDetails.package_title}`}
              className="w-full h-auto rounded-lg mt-2"
            />
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Additional Images</h2>
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              {Array.isArray(tourDetails.package_images) && tourDetails.package_images.length > 0 ? (
                tourDetails.package_images.map((image, idx) => (
                  <img
                    key={idx}
                    src={getImageUrl(image)}
                    alt={`Additional  ${idx + 1} of ${tourDetails.package_title}`}
                    className="w-full h-auto rounded-lg"
                  />
                ))
              ) : (
                <p>No additional images available.</p>
              )}
            </div>
          </div>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
          <h2 className="text-lg font-semibold text-green-700">Itinerary</h2>
          <ul className="list-disc pl-5 space-y-2">
            {Array.isArray(tourDetails.package_itinerary) &&
              tourDetails.package_itinerary.map((item, idx) => (
                <li key={idx} className="text-gray-700">
                  <h3 className="font-semibold">{item.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </li>
              ))}
          </ul>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-800">Inclusions</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.package_inclusions) &&
                tourDetails.package_inclusions.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item}</li>
                ))}
            </ul>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-800">Exclusions</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.package_exclusions) &&
                tourDetails.package_exclusions.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item}</li>
                ))}
            </ul>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-800">Activities</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.activities) &&
                tourDetails.activities.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item}</li>
                ))}
            </ul>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-800">Tour Type</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.tour_type) &&
                tourDetails.tour_type.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item}</li>
                ))}
            </ul>
          </div>

        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Price</h2>
            <p className="text-gray-700">{tourDetails.package_price} USD</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Rates</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.rates) &&
                tourDetails.rates.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item.title} - {item.amount} </li>
                ))}
            </ul>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Rate Dates</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.rate_dates) &&
                tourDetails.rate_dates.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item.start} - {item.end}</li>
                ))}
            </ul>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Destinations</h2>
            <p className="text-gray-700">{getDestinationName(tourDetails.destination_id)}</p>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Package Attractions</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.package_location) &&
                tourDetails.package_location.map((location, idx) => (
                  <li key={idx} className="text-gray-700">{location}</li>
                ))}
            </ul>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Duration</h2>
            <p className="text-gray-700">
              {tourDetails.package_days} Days, {tourDetails.package_nights} Nights
            </p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Age Range</h2>
            <p className="text-gray-700">Minimum Age: {tourDetails.package_minage}</p>
            <p className="text-gray-700">Maximum People: {tourDetails.package_maxpeople}</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-700">Special Features</h2>
            <p className="text-gray-700">
              {tourDetails.free_cancelation ? 'Free Cancellation Available' : 'No Free Cancellation'}
            </p>
            <p className="text-gray-700">
              {tourDetails.best_price_guarantee ? 'Best Price Guarantee' : 'No Best Price Guarantee'}
            </p>
            <p className="text-gray-700">
              {tourDetails.is_popular ? 'Popular Tour' : 'Not Popular'}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm">
            <h2 className="text-lg font-semibold text-green-800">Requirements</h2>
            <ul className="list-disc pl-5 space-y-2">
              {Array.isArray(tourDetails.package_requirements) &&
                tourDetails.package_requirements.map((item, idx) => (
                  <li key={idx} className="text-gray-700">{item}</li>
                ))}
            </ul>
          </div>
        </div>
      </div>
  );
};

export default TourDetails;
