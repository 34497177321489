import { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';

const AddUser = ({ isOpen, onClose }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const api_url = `${API_URL}/admin/createAdmin`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken(); 
      console.log('Token:', token); 
      const response = await axios.post(api_url, {
        firstName,
        lastName,
        email,
        password,
      }, {
        headers: {
          'Authorization': `${token}`,
        },
      });

      if (response.status === 200) {
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      onClose();
      }else{
        throw new Error('Failed to add admin');
      }
    } catch (error) {
      setError('Failed to add admin. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-xl font-bold mb-4">Add New Admin</h2>
        {error && <div className="text-red-600 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="border rounded w-full px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="border rounded w-full px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border rounded w-full px-3 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border rounded w-full px-3 py-2"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 border rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-800 text-white px-4 py-2 rounded"
            >
              Add Admin
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUser;
