import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL } from "../variables";
import Loader from '../components/Loader';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaTiktok } from 'react-icons/fa';

const OrganizationSettings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgData, setOrgData] = useState({
    organization_name: '',
    organization_email: '',
    organization_phone: '',
    organization_address: '',
    organization_facebook: '',
    organization_instagram: '',
    organization_twitter: '',
    organization_linkedin: '',
    organization_youtube: '',
    organization_tiktok: '',
  });
  const [logo, setLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(null);

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  const fetchOrganizationData = async () => {
    try {
      const response = await axios.get(`${API_URL}/organization/getOrganizationData`);
      setOrgData(response.data);
      if (response.data.organization_logo) {
        setPreviewLogo(response.data.organization_logo);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching organization data:', error);
      toast.error('Failed to fetch organization data');
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrgData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLogo(file);
      setPreviewLogo(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const token = localStorage.getItem('authToken');
      const formData = new FormData();
      Object.keys(orgData).forEach(key => {
        formData.append(key, orgData[key]);
      });
      if (logo) {
        formData.append('organization_logo', logo);
      }

      await axios.post(
        `${API_URL}/organization/updateOrganization`,
        formData,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      toast.success('Organization details updated successfully');
    } catch (error) {
      console.error('Error updating organization:', error);
      toast.error('Failed to update organization details');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="min-h-screen">
      <div className="max-w-4xl">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-6">Organization Settings</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Organization Logo
              </label>
              <div className="flex items-center space-x-4">
                {previewLogo ? (
                  <img
                    src={previewLogo}
                    alt="Organization Logo"
                    className="w-24 h-24 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-24 h-24 bg-gray-100 flex items-center justify-center rounded-lg">
                    <span className="text-gray-400 text-sm">No Logo</span>
                  </div>
                )}
                <input
                  type="file"
                  onChange={handleLogoChange}
                  accept="image/*"
                  className="text-sm"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Organization Name
                </label>
                <input
                  type="text"
                  name="organization_name"
                  value={orgData.organization_name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  name="organization_email"
                  value={orgData.organization_email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone
                </label>
                <input
                  type="text"
                  name="organization_phone"
                  value={orgData.organization_phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Address
                </label>
                <input
                  type="text"
                  name="organization_address"
                  value={orgData.organization_address}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                />
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-700">Social Media Links</h3>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaFacebook className="mr-2 text-blue-600" />
                    Facebook
                  </label>
                  <input
                    type="text"
                    name="organization_facebook"
                    value={orgData.organization_facebook}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaInstagram className="mr-2 text-pink-600" />
                    Instagram
                  </label>
                  <input
                    type="text"
                    name="organization_instagram"
                    value={orgData.organization_instagram}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaTwitter className="mr-2 text-blue-400" />
                    Twitter
                  </label>
                  <input
                    type="text"
                    name="organization_twitter"
                    value={orgData.organization_twitter}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaLinkedin className="mr-2 text-blue-700" />
                    LinkedIn
                  </label>
                  <input
                    type="text"
                    name="organization_linkedin"
                    value={orgData.organization_linkedin}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaYoutube className="mr-2 text-red-600" />
                    YouTube
                  </label>
                  <input
                    type="text"
                    name="organization_youtube"
                    value={orgData.organization_youtube}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>

                <div>
                  <label className="flex items-center text-sm font-medium text-gray-700 mb-1">
                    <FaTiktok className="mr-2" />
                    TikTok
                  </label>
                  <input
                    type="text"
                    name="organization_tiktok"
                    value={orgData.organization_tiktok}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 text-sm border border-gray-200 rounded-md"
                  />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className="px-4 py-2 bg-green-800 text-white rounded-md hover:bg-green-600 transition-colors duration-200"
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSettings;