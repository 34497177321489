import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import { getToken } from '../authService';
import Loader from './Loader';
import AddReferral from './AddReferral';
import QRCodeComponent from './QRCode';


function ReferralsList({ onAddClick }) {
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingReferral, setEditingReferral] = useState(null);
  const [showQRModal, setShowQRModal] = useState(false);
  const [selectedReferral, setSelectedReferral] = useState(null);

  useEffect(() => {
    fetchReferrals();
  }, []);

  const fetchReferrals = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/referrals/getAllReferrals`, {
        headers: { "Authorization": `Bearer ${token}` }
      });
      setReferrals(response.data);
    } catch (error) {
      console.error('Error fetching referrals:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (referralId) => {
    try {
      const token = getToken();
      await axios.post(`${API_URL}/referrals/deleteReferral`, {id: referralId}, {
        headers: { "Authorization": ` ${token}` }
      });
      fetchReferrals();
    } catch (error) {
      console.error('Error deleting referral:', error);
    }
  };

  const handleEdit = (referral) => {
    setEditingReferral(referral);
  };

  const handleCloseEdit = () => {
    setEditingReferral(null);
    fetchReferrals(); 
  };
  const handleViewQR = (referral) => {
    setSelectedReferral(referral);
    setShowQRModal(true);
  };

  const handleCloseQRModal = () => {
    setShowQRModal(false);
    setSelectedReferral(null);
  };

  const handleViewReservations = (referral) => {
    const reservationsCount = 
      (referral.referral_reservations?.length || 0) + 
      (referral.referral_custom_reservations?.length || 0);
    
    if (reservationsCount > 0) {
      console.log(`Viewing reservations for referral ID: ${referral.id}`);
    }
  };

  return (
    <div className="p-4 bg-white rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-green-800">Referrals List</h2>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-end items-center mb-6">

            <button
              onClick={onAddClick}
              className="bg-green-800 text-yellow-300 px-4 py-2 rounded hover:bg-green-600 flex items-center"
            >
              Add Referral <span className="ml-2 text-xl">+</span>
            </button>
          </div>

          <div className="shadow-md rounded-lg">
            <table className="min-w-full bg-white border border-gray-200">
              <thead className="bg-green-800 text-white">
                <tr>
                  <th className="px-3 py-2 text-left text-sm">ID</th>
                  <th className="px-3 py-2 text-left text-sm">Code</th>
                  <th className="px-3 py-2 text-left text-sm">URL</th>
                  <th className="px-3 py-2 text-left text-sm">Reservations</th>
                  <th className="px-3 py-2 text-left text-sm">QR Code</th>
                  <th className="px-3 py-2 text-left text-sm">Actions</th>
                </tr>
              </thead>
              <tbody>
                {referrals.length > 0 ? (
                  referrals.map((referral) => (
                    <tr key={referral.id} className="border-t border-gray-200">
                      <td className="px-3 py-2 text-sm">{referral.id}</td>
                      <td className="px-3 py-2 text-sm">{referral.code}</td>
                      
                      <td className="px-3 py-2 text-sm">{referral.url}</td>
                      <td className="px-3 py-2 text-sm ">
                        <button 
                          className="bg-green-100 text-green-800 w-8 h-8 rounded-full text-sm hover:bg-green-800 hover:text-white flex items-center justify-center"
                          onClick={() => handleViewReservations(referral)}
                        >
                          {(referral.referral_reservations?.length || 0) + 
                           (referral.referral_custom_reservations?.length || 0)}
                        </button>
                      </td>
                      <td className="px-3 py-2 text-sm">
                        <button 
                          className="bg-green-100 text-green-800 px-2 py-1  font-bold rounded text-sm hover:bg-green-800 hover:text-white"
                          onClick={() => handleViewQR(referral)}
                        >
                          View QR
                        </button>
                      </td>
                      <td className="px-3 py-2 text-sm">
                        <button 
                          className="text-green-800 mr-3"
                          onClick={() => handleEdit(referral)}
                        >
                          <FaEdit />
                        </button>
                        <button 
                          className="text-yellow-400"
                          onClick={() => handleDelete(referral.id)}
                        >
                          <FaTrashAlt />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center px-3 py-2 border-t border-gray-200 text-sm">
                      No referrals found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {editingReferral && (
            <AddReferral 
              onClose={handleCloseEdit}
              initialData={editingReferral}
            />
          )}

          {showQRModal && selectedReferral && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg max-w-md w-full">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold">QR Code</h3>
                  <button 
                    onClick={handleCloseQRModal}
                    className="text-gray-500 hover:text-gray-700 text-2xl"
                  >
                    ×
                  </button>
                </div>
                <QRCodeComponent referral={selectedReferral} />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ReferralsList;

