import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import Loader from '../components/Loader';
import { FaTrash } from 'react-icons/fa';

const SubscriptionsPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/subscription/getAllSubscriptions`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setSubscriptions(response.data);
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (subscriptionId) => {
    if (window.confirm('Are you sure you want to delete this subscription?')) {
      try {
        const token = getToken();
        const formData = new FormData();
        formData.append('subscription_id', subscriptionId);

        await axios.post(`${API_URL}/subscription/deleteSubscription`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        fetchSubscriptions(); // Refresh the list after deletion
      } catch (error) {
        console.error("Error deleting subscription:", error);
      }
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Subscriptions</h1>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white rounded-lg shadow">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Email</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {subscriptions.map((subscription) => (
                <tr key={subscription.subscription_id} className="hover:bg-gray-50">
                  <td className="px-4 py-2 text-sm">{subscription.subscription_name}</td>
                  <td className="px-4 py-2 text-sm">{subscription.subscription_email}</td>
                  <td className="px-4 py-2 text-sm">
                    {new Date(subscription.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleDelete(subscription.subscription_id)}
                      className="text-red-600 hover:text-red-900"
                      title="Delete subscription"
                    >
                      <FaTrash size={14} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {subscriptions.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No subscriptions found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionsPage; 