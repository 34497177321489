import React, { useState } from 'react'
import ReferralsList from '../components/ReferralsList'
import AddReferral from '../components/AddReferral'

function Referrals() {
  const [showAddReferral, setShowAddReferral] = useState(false);

  return (
    <div>
      <ReferralsList onAddClick={() => setShowAddReferral(true)} />
      {showAddReferral && (
        <AddReferral onClose={() => setShowAddReferral(false)} />
      )}
    </div>
  )
}

export default Referrals