import React from 'react'
import UserTable from '../components/UserTable';
function Users() {
  return (
      <div className="flex-1">
        <UserTable />
      </div>
  );
};

export default Users;