import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropdown from './Multiselect'
import Loader from './Loader';
import { useImage } from './ImageContext';
import { FaPlus, FaTimes } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const api_url = `${API_URL}/package/updatePackage`;
const TourEditForm = ({onSave, onClose }) => {
  const navigate = useNavigate();
  const { packageId } = useParams();
  const { getImageUrl } = useImage();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [activities, setActivities] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    package_title: '',
    package_summary: '',
    package_image: '',
    package_images: [],
    package_location: [],
    destination_id: [],
    activities: [],
    tour_type: [],
    package_days: '',
    package_nights: '',
    package_price: '',
    package_itinerary: [{ title: '', content: '' }],
    package_inclusions: [],
    package_exclusions: [],
    package_requirements: [],
    rates: [],
    rate_dates: [],
    package_minage: '',
    package_maxpeople: '',
    free_cancelation: false,
    best_price_guarantee: false,
    is_popular: false,
  });
  const tourTypes = [
    { value: 'Solo', label: 'Solo' },
    { value: 'Family Tour', label: 'Family Tour' },
    { value: 'Group Tour', label: 'Group Tour' },
  ];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'], 
      [{ 'list': 'ordered'}, { 'list': 'bullet' }], 
      [{ 'align': [] }], 
      [{ 'color': [] }], 
      ['link'], 
      ['clean'] 
    ],
  };

  const formats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'align',
    'color',
    'link'
  ];

  const predefinedLocations = {
    Rwanda: [
      "Volcanoes National Park",
      "Nyungwe Forest National Park",
      "Akagera National Park",
      "Lake Kivu",
      "Kigali Genocide Memorial",
      "Ethnographic Museum",
      "King's Palace Museum",
      "Gishwati-Mukura National Park",
      "Mount Bisoke",
      "Rusumo Falls",
      "Musanze Caves",
      "Kigali City",
      "Bigogwe",
      "Fazenda Sengha",
      "Twin Lakes (Burera & Ruhondo)",
      "Rugezi Marsh",
      "Kimironko Market",
      "Inema Arts Center"
    ],
    Uganda: [
      "Bwindi Impenetrable National Park",
      "Queen Elizabeth National Park",
      "Murchison Falls National Park",
      "Kibale National Park",
      "Lake Victoria",
      "Source of the Nile",
      "Rwenzori Mountains National Park",
      "Sipi Falls",
      "Ziwa Rhino Sanctuary",
      "Mgahinga Gorilla National Park",
      "Lake Bunyonyi",
      "Mount Elgon National Park",
      "Semuliki National Park",
      "Jinja Town",
      "Kasubi Tombs"
    ]
  };

  useEffect(() => {
    console.log('Fetched packageId:', packageId); 
    const fetchTourData = async () => {
      try {
        const token = getToken();
        const { data } = await axios.get(`${API_URL}/package/getPackagesById/${packageId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        
        // Parse the data
        const parsedData = {
          ...data,
          destination_id: Array.isArray(data.destination_id) ? 
            data.destination_id : 
            (typeof data.destination_id === 'string' ? 
              JSON.parse(data.destination_id) : 
              [data.destination_id].filter(Boolean)),
          package_location: Array.isArray(data.package_location) ?
            data.package_location :
            data.package_location ? data.package_location.split(',').map(item => item.trim()) :
            [],
          package_inclusions: data.package_inclusions || [],
          package_exclusions: data.package_exclusions || [],
          package_images: data.package_images || [],
        };
        
        setFormData(parsedData);
      } catch (error) {
        setError('Failed to load tour data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [packageId]);

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: uploadedFileName,
            }));
          }
        } catch (error) {
          setError('Failed to upload image. Please try again.');
        }
      }
    } else if (name.startsWith('itinerary[')) {
      const index = parseInt(name.match(/\d+/)[0], 10);
      const field = name.includes('title') ? 'title' : 'content';
      setFormData(prevFormData => {
        const updatedItinerary = [...prevFormData.package_itinerary];
        updatedItinerary[index] = { ...updatedItinerary[index], [field]: value };
        return { ...prevFormData, package_itinerary: updatedItinerary };
      });
    } else if (name.endsWith('[]')) {
      const fieldName = name.replace('[]', '');
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: value.split(',').map(item => item.trim()),
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      }));
    }
  };

  const handleFileChange = async (files) => {
    const fileArray = Array.from(files);
    const uploadedFileNames = [];
    try {
      const uploadPromises = fileArray.map(file => handleImageUpload(file));
      const results = await Promise.all(uploadPromises);
      results.forEach(result => {
        if (result) uploadedFileNames.push(result);
      });
      setFormData(prevFormData => {
        const updatedImages = [...prevFormData.package_images, ...uploadedFileNames];
        return {
          ...prevFormData,
          package_images: updatedImages,
        };
      });
    } catch (error) {
      setError('Failed to upload images. Please try again.');
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload response:', data);
      if (status === 200) return data.file_name;
      throw new Error('Image upload failed');
    } catch (error) {
      console.error('Error during image upload:', error);
      setError('Failed to upload image. Please try again.');
    }
  };

  const addItineraryItem = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_itinerary: [...prevFormData.package_itinerary, { title: '', content: '' }]
    }));
  };
  const addRate = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rates: [...prevFormData.rates, { title: '', amount: '' }]
    }));
  };
  
  const addRateDate = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rate_dates: [...prevFormData.rate_dates, { start: '', end: '' }]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = getToken();
      if (!token) throw new Error('No authorization token found.');

      const formattedData = {
        ...formData,
        activities: JSON.stringify(formData.activities),
        best_price_guarantee: formData.best_price_guarantee,
        destination_id: JSON.stringify(formData.destination_id),
        free_cancelation: formData.free_cancelation,
        is_popular: formData.is_popular,
        package_days: Number(formData.package_days),
        package_exclusions: JSON.stringify(formData.package_exclusions),
        package_image: formData.package_image,
        package_images: JSON.stringify(formData.package_images),
        package_inclusions: JSON.stringify(formData.package_inclusions),
        package_itinerary: JSON.stringify(formData.package_itinerary),
        package_location: JSON.stringify(formData.package_location),
        package_maxpeople: Number(formData.package_maxpeople),
        package_minage: Number(formData.package_minage),
        package_nights: Number(formData.package_nights),
        package_price: Number(formData.package_price),
        package_requirements: JSON.stringify(formData.package_requirements),
        rates: JSON.stringify(formData.rates),
        rate_dates: JSON.stringify(formData.rate_dates),
        package_summary: formData.package_summary,
        tour_type: JSON.stringify(formData.tour_type),
      };

      const response = await axios.post(api_url, formattedData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Package updated successfully!');
        if (onSave) {
          onSave(formattedData);
        }
        navigate('/tours'); 
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    }  catch (error) {
      if (!error.response || error.response.status !== 200) {
        setError(error.response?.data?.message || 'Failed to update package. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${API_URL}/activity/getAllActivities`,{
          headers: {
            'Authorization': `${token}`,
          },
        });
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error.message);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityChange = (selectedValues) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      activities: selectedValues
    }));
  };
  const fetchDestinations = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/destination/getAllDestinations`,{
        headers: {
          'Authorization': `${token}`,
        },
      });
      setDestinations(response.data); 
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleTourTypeChange = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      tour_type: selectedOptions
    }));
  };

  const removeImage = (imageName) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_images: prevFormData.package_images.filter(img => img !== imageName),
    }));
  };

  const addInclusion = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_inclusions: [...prevFormData.package_inclusions, '']
    }));
  };

  const handleInclusionChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedInclusions = [...prevFormData.package_inclusions];
      updatedInclusions[index] = value;
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const removeInclusion = (index) => {
    setFormData(prevFormData => {
      const updatedInclusions = prevFormData.package_inclusions.filter((_, i) => i !== index);
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const addExclusion = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_exclusions: [...prevFormData.package_exclusions, '']
    }));
  };

  const handleExclusionChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedExclusions = [...prevFormData.package_exclusions];
      updatedExclusions[index] = value;
      return { ...prevFormData, package_exclusions: updatedExclusions };
    });
  };

  const removeExclusion = (index) => {
    setFormData(prevFormData => {
      const updatedExclusions = prevFormData.package_exclusions.filter((_, i) => i !== index);
      return { ...prevFormData, package_exclusions: updatedExclusions };
    });
  };

  const addRequirement = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_requirements: [...prevFormData.package_requirements, '']
    }));
  };

  const handleRequirementChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedRequirements = [...prevFormData.package_requirements];
      updatedRequirements[index] = value;
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeRequirement = (index) => {
    setFormData(prevFormData => {
      const updatedRequirements = prevFormData.package_requirements.filter((_, i) => i !== index);
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeItineraryItem = (index) => {
    setFormData(prevFormData => {
      const updatedItinerary = prevFormData.package_itinerary.filter((_, i) => i !== index);
      return { ...prevFormData, package_itinerary: updatedItinerary };
    });
  };

  const handleQuillChange = (index, content) => {
    setFormData(prev => {
      const updatedItinerary = [...prev.package_itinerary];
      updatedItinerary[index] = {
        ...updatedItinerary[index],
        content: content
      };
      return {
        ...prev,
        package_itinerary: updatedItinerary
      };
    });
  };

  const handleItineraryImageUpload = async (index, file) => {
    if (file) {
      try {
        const uploadedFileName = await handleImageUpload(file);
        if (uploadedFileName) {
          setFormData(prev => {
            const updatedItinerary = [...prev.package_itinerary];
            updatedItinerary[index] = {
              ...updatedItinerary[index],
              image: uploadedFileName
            };
            return {
              ...prev,
              package_itinerary: updatedItinerary
            };
          });
        }
      } catch (error) {
        console.error('Error uploading itinerary image:', error);
        setError('Failed to upload itinerary image. Please try again.');
      }
    }
  };

  const handleRemoveItineraryImage = (index) => {
    setFormData(prev => {
      const updatedItinerary = [...prev.package_itinerary];
      updatedItinerary[index] = {
        ...updatedItinerary[index],
        image: ''
      };
      return {
        ...prev,
        package_itinerary: updatedItinerary
      };
    });
  };

  const handleRateChange = (index, field, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rates: prevFormData.rates.map((rate, i) => 
        i === index ? { ...rate, [field]: value } : rate
      )
    }));
  };

  const handleRateDateChange = (index, field, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rate_dates: prevFormData.rate_dates.map((date, i) =>  
        i === index ? { ...date, [field]: value } : date
      )
    }));
  };

  const removeRate = (index) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rates: prevFormData.rates.filter((_, i) => i !== index)
    }));
  };

  const removeRateDate = (index) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      rate_dates: prevFormData.rate_dates.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="max-w-7xl mx-auto p-8 mb-10 bg-white rounded-lg shadow-sm">
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-6">Edit Package Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Package Title */}
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Package Title</label>
              <input
                type="text"
                name="package_title"
                value={formData.package_title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>

            {/* Package Summary */}
            <div className="col-span-2 mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Package Summary</label>
              <div className="quill-wrapper" style={{ marginBottom: '3rem' }}>
                <ReactQuill
                  value={formData.package_summary || ''}
                  onChange={(content) => {
                    setFormData(prev => ({
                      ...prev,
                      package_summary: content
                    }));
                  }}
                  modules={modules}
                  formats={formats}
                  theme="snow"
                  style={{ height: '140px' }}
                  placeholder="Enter package summary..."
                />
              </div>
            </div>

            {/* Package Image */}
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Package Image</label>
              <input
                type="file"
                name="package_image"
                onChange={handleChange}
                className="mt-1 block w-full"
              />
            </div>

            {/* Additional Images */}
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Additional Images</label>
              <div className="flex flex-wrap gap-2">
                {formData.package_images.map((image, index) => (
                  <div key={index} className="relative">
                    <img
                      src={getImageUrl(image)}
                      alt={`Additional  ${index + 1}`}
                      className="w-32 h-32 object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(image)}
                      className="absolute top-0 right-0 text-red-500"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ))}
                <input
                  type="file"
                  multiple
                  onChange={(e) => handleFileChange(e.target.files)}
                  className="mt-1 block w-full"
                />
              </div>
            </div>

            {/* Location */}
            <div>
              <MultiSelectDropdown
                options={Object.entries(predefinedLocations).flatMap(([country, locations]) =>
                  locations.map(location => ({
                    value: location,
                    label: `${location} (${country})`
                  }))
                )}
                selectedOptions={Array.isArray(formData.package_location) ? 
                  formData.package_location : 
                  formData.package_location ? formData.package_location.split(',').map(item => item.trim()) : 
                  []
                }
                onChange={(selected) => {
                  setFormData(prev => ({
                    ...prev,
                    package_location: selected
                  }));
                }}
                label="Select Attractions"
                allowCustom={true}
                placeholder="Select or type attractions"
              />
            </div>

            {/* Destination */}
            <div>
              <MultiSelectDropdown
                options={destinations.map(destination => ({
                  value: destination.destination_name,
                  label: destination.destination_name
                }))}
                selectedOptions={
                  Array.isArray(formData.destination_id) 
                    ? formData.destination_id.map(id => {
                        const destination = destinations.find(d => d.destination_id === id);
                        return destination ? destination.destination_name : '';
                      }).filter(Boolean)
                    : []
                }
                onChange={(selectedValues) => {
                  const selectedIds = selectedValues.map(name => {
                    const destination = destinations.find(d => d.destination_name === name);
                    return destination ? destination.destination_id : null;
                  }).filter(Boolean);
                  
                  setFormData(prev => ({
                    ...prev,
                    destination_id: selectedIds
                  }));
                }}
                label="Select Destinations"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Min Age</label>
              <input
                type="number"
                name="package_minage"
                value={formData.package_minage}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter minimum age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Max People</label>
              <input
                type="number"
                name="package_maxpeople"
                value={formData.package_maxpeople}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter maximum number of people"
              />
            </div>
            <div>
              <MultiSelectDropdown
                options={activities.map(activity => ({
                  value: activity.activity_name,
                  label: activity.activity_name
                }))}
                selectedOptions={formData.activities}
                onChange={handleActivityChange}
                label="Select Activities"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tour Type</label>
              <MultiSelectDropdown
                options={tourTypes}
                selectedOptions={formData.tour_type}
                onChange={handleTourTypeChange}
              />
            </div>

            {/* Days */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Days</label>
              <input
                type="number"
                name="days"
                value={formData.package_days}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Nights</label>
              <input
                type="number"
                name="package_nights"
                value={formData.package_nights}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of nights"
              />
            </div>

            {/* Price */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="package_price"
                value={formData.package_price}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            {/* Rates Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 flex justify-between mb-2">
                Rates
                <button
                  type="button"
                  onClick={addRate}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Rate"
                >
                  <FaPlus size={14} />
                </button>
              </label>
              {formData.rates.map((rate, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <input
                    type="text"
                    value={rate.title}
                    onChange={(e) => handleRateChange(index, 'title', e.target.value)}
                    className="border rounded p-2 flex-1"
                    placeholder="Rate Title"
                  />
                  <input
                    type="number"
                    value={rate.amount}
                    onChange={(e) => handleRateChange(index, 'amount', e.target.value)}
                    className="border rounded p-2 flex-1"
                    placeholder="Rate Amount"
                  />
                  <button
                    type="button"
                    onClick={() => removeRate(index)}
                    className="text-red-500 hover:text-red-600"
                    title="Remove Rate"
                  >
                    <FaTimes size={14} />
                  </button>
                </div>
              ))}
            </div>
            {/* Rate Dates Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 flex justify-between">
                Rate Dates
                <button
                  type="button"
                  onClick={addRateDate}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Rate Date"
                >
                  <FaPlus size={14} />
                </button>
              </label>
              {formData.rate_dates.map((date, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="date"
                    value={date.start}
                    onChange={(e) => handleRateDateChange(index, 'start', e.target.value)}
                    className="border rounded p-2 w-full"
                  />
                  <input
                    type="date"
                    value={date.end}
                    onChange={(e) => handleRateDateChange(index, 'end', e.target.value)}
                    className="border rounded p-2 w-full"
                  />
                  <button
                    type="button"
                    onClick={() => removeRateDate(index)}
                    className="text-red-500 hover:text-red-600"
                  >
                    <FaTimes size={14} />
                  </button>
                </div>
              ))}
            </div>
            {/* {Itinerary} */}
            <div className="col-span-2">
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Itinerary</label>
                <button
                  type="button"
                  onClick={addItineraryItem}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Itinerary Item"
                >
                  <FaPlus size={14} />
                </button>
              </div>
              {formData.package_itinerary.map((item, index) => (
                <div key={index} className="mb-6 bg-white rounded-lg border border-gray-100 p-4">
                  <div className="flex items-center gap-2 mb-4">
                    <input
                      type="text"
                      name={`itinerary[${index}].title`}
                      value={item.title}
                      onChange={handleChange}
                      className="block w-full border-0 border-b border-gray-200 py-1.5 px-0 focus:ring-0 focus:border-green-500"
                      placeholder="Day title"
                    />
                    <button
                      type="button"
                      onClick={() => removeItineraryItem(index)}
                      className="text-gray-400 hover:text-red-500"
                    >
                      <FaTimes size={14} />
                    </button>
                  </div>

                  {/* Image Section */}
                  <div className="mb-4">
                    <div className="flex items-center gap-4">
                      <div className="w-1/3">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleItineraryImageUpload(index, e.target.files[0])}
                          className="hidden"
                          id={`itinerary-image-${index}`}
                        />
                        <label
                          htmlFor={`itinerary-image-${index}`}
                          className="cursor-pointer block w-full"
                        >
                          {item.image ? (
                            <div className="relative group">
                              <img
                                src={getImageUrl(item.image)}
                                alt={`Day ${index + 1}`}
                                className="w-full h-48 object-cover rounded-lg"
                              />
                              <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                                <span className="text-white text-sm">Change Image</span>
                              </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleRemoveItineraryImage(index);
                                }}
                                className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                              >
                                <FaTimes size={12} />
                              </button>
                            </div>
                          ) : (
                            <div className="w-full h-48 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center hover:border-gray-400 transition-colors">
                              <div className="text-center">
                                <FaPlus className="mx-auto text-gray-400" size={24} />
                                <span className="mt-2 block text-sm text-gray-400">Add Image</span>
                              </div>
                            </div>
                          )}
                        </label>
                      </div>

                      <div className="w-2/3">
                        <ReactQuill
                          value={item.content || ''}
                          onChange={(content) => handleQuillChange(index, content)}
                          modules={modules}
                          formats={formats}
                          theme="snow"
                          style={{ height: '200px' }}
                          className="mb-4"
                          placeholder="Day description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Requirements, Inclusions & Exclusions Grid */}
            <div className="grid grid-cols-3 gap-6 col-span-2">
              {/* Requirements */}
              <div className="bg-white rounded-lg border border-gray-100">
                <div className="flex items-center justify-between p-3 border-b">
                  <label className="text-sm font-medium text-gray-700">Requirements</label>
                  <button
                    type="button"
                    onClick={addRequirement}
                    className="text-green-600 hover:text-green-700"
                    title="Add Requirement"
                  >
                    <FaPlus size={12} />
                  </button>
                </div>
                <div className="p-3">
                  {formData.package_requirements.map((requirement, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2 last:mb-0 group">
                      <input
                        type="text"
                        value={requirement}
                        onChange={(e) => handleRequirementChange(index, e.target.value)}
                        className="block w-full text-sm border-0 border-b border-gray-200 py-1.5 px-0 focus:ring-0 focus:border-green-500"
                        placeholder="Enter requirement"
                      />
                      <button
                        type="button"
                        onClick={() => removeRequirement(index)}
                        className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <FaTimes size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Inclusions */}
              <div className="bg-white rounded-lg border border-gray-100">
                <div className="flex items-center justify-between p-3 border-b">
                  <label className="text-sm font-medium text-gray-700">Inclusions</label>
                  <button
                    type="button"
                    onClick={addInclusion}
                    className="text-green-600 hover:text-green-700"
                    title="Add Inclusion"
                  >
                    <FaPlus size={12} />
                  </button>
                </div>
                <div className="p-3">
                  {formData.package_inclusions.map((inclusion, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2 last:mb-0 group">
                      <input
                        type="text"
                        value={inclusion}
                        onChange={(e) => handleInclusionChange(index, e.target.value)}
                        className="block w-full text-sm border-0 border-b border-gray-200 py-1.5 px-0 focus:ring-0 focus:border-green-500"
                        placeholder="Enter inclusion"
                      />
                      <button
                        type="button"
                        onClick={() => removeInclusion(index)}
                        className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <FaTimes size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Exclusions */}
              <div className="bg-white rounded-lg border border-gray-100">
                <div className="flex items-center justify-between p-3 border-b">
                  <label className="text-sm font-medium text-gray-700">Exclusions</label>
                  <button
                    type="button"
                    onClick={addExclusion}
                    className="text-green-600 hover:text-green-700"
                    title="Add Exclusion"
                  >
                    <FaPlus size={12} />
                  </button>
                </div>
                <div className="p-3">
                  {formData.package_exclusions.map((exclusion, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2 last:mb-0 group">
                      <input
                        type="text"
                        value={exclusion}
                        onChange={(e) => handleExclusionChange(index, e.target.value)}
                        className="block w-full text-sm border-0 border-b border-gray-200 py-1.5 px-0 focus:ring-0 focus:border-green-500"
                        placeholder="Enter exclusion"
                      />
                      <button
                        type="button"
                        onClick={() => removeExclusion(index)}
                        className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <FaTimes size={12} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Special Features */}
            <div className="bg-white rounded-lg border border-gray-100 p-3">
              <div className="grid grid-cols-3 gap-6">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="free_cancelation"
                    checked={formData.free_cancelation}
                    onChange={handleChange}
                    className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                  />
                  <span className="text-sm text-gray-700">Free Cancelation</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="best_price_guarantee"
                    checked={formData.best_price_guarantee}
                    onChange={handleChange}
                    className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                  />
                  <span className="text-sm text-gray-700">Best Price Guarantee</span>
                </label>
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="is_popular"
                    checked={formData.is_popular}
                    onChange={handleChange}
                    className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                  />
                  <span className="text-sm text-gray-700">Mark as Popular</span>
                </label>
              </div>
            </div>

            {/* Buttons */}
            <div className="col-span-2 flex justify-between mt-4">
              <button
                type="button"
                onClick={onClose}
                className="py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={loading}
                className="py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600"
              >
                {loading ? 'Saving...' : 'Save Changes'}
              </button>
            </div>
          </div>

          {/* Error and Success Messages */}
          {error && (
            <div className="text-red-600 mt-4">{error}</div>
          )}
          {successMessage && (
            <div className="text-green-600 mt-4">{successMessage}</div>
          )}
        </form>
      )}
    </div>
  );
};

export default TourEditForm;