import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { FaCheck, FaTimes, FaFilter, FaCalendarAlt } from 'react-icons/fa';
import { getToken } from '../authService';
import Loader from '../components/Loader'; 

const ReservedTickets = ({ setCount }) => {
  const [reservations, setReservations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [referralFilter, setReferralFilter] = useState('');

  const fetchData = useCallback(async () => {
    const token = getToken();
    try {
      const [reservationsResponse, packagesResponse, referralsResponse] = await Promise.all([
        axios.get(`${API_URL}/reservations/getAllReservations`, {
          headers: { "Authorization": `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/package/getAllPackages`, {
          headers: { "Authorization": `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/referrals/getAllReferrals`, {
          headers: { "Authorization": `Bearer ${token}` },
        }),
      ]);

      setReservations(reservationsResponse.data);
      setPackages(packagesResponse.data);
      setReferrals(referralsResponse.data);
      if (setCount) setCount(reservationsResponse.data.length);
    } catch (error) {
      console.error('Error fetching data:', error.response || error.message);
    } finally {
      setLoading(false);
    }
  }, [setCount]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getPackageTitle = (packageId) => {
    const tourPackage = packages.find((pkg) => pkg.package_id === packageId);
    return tourPackage ? tourPackage.package_title : "Unknown Package";
  };

  const getReferralName = (referralId) => {
    const referral = referrals.find(ref => ref.id === referralId);
    return referral ? referral.code : 'Direct';
  };

  const handleAccept = async (reservationId, reservationEmail) => {
    try {
      const token = getToken();
      await axios.post(`${API_URL}/reservations/updateReservation`, {
        reservation_id: reservationId,
        status: 'accepted',
      }, {
        headers: { "Authorization": `Bearer ${token}` },
      });
      window.location.href = `mailto:${reservationEmail}?subject=Reservation Accepted&body=Your reservation has been accepted.`;
      fetchData();
    } catch (error) {
      console.error('Error accepting reservation:', error.response || error.message);
    }
  };

  const handleReject = async (reservationId) => {
    try {
      const token = getToken();
      await axios.post(`${API_URL}/reservations/deleteReservation`, {
        reservation_id: reservationId,
        status: 'rejected',
      }, {
        headers: { "Authorization": `Bearer ${token}` },
      });
      fetchData();
    } catch (error) {
      console.error('Error rejecting reservation:', error.response || error.message);
    }
  };

  const filteredReservations = reservations.filter(reservation => {
    const reservationDate = new Date(reservation.reservation_date);
    const matchesDateRange = (!startDate || reservationDate >= new Date(startDate)) && 
                           (!endDate || reservationDate <= new Date(endDate));
    
    const matchesReferral = !referralFilter || 
      getReferralName(reservation.referral_id).toLowerCase() === referralFilter.toLowerCase();
    
    return matchesDateRange && matchesReferral;
  });

  return (
    <div className="mx-auto bg-gray-50">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-xl font-bold text-green-800">Reservations</h1>
        
        {/* Filter Controls */}
        <div className="flex items-center gap-4 bg-white p-3 rounded-lg shadow-sm">
          <div className="flex items-center gap-2">
            <FaCalendarAlt className="text-green-800" />
            <div className="flex items-center gap-2">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
                placeholder="Start Date"
              />
              <span className="text-gray-500">to</span>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
                placeholder="End Date"
              />
            </div>
          </div>
          
          <div className="flex items-center gap-2">
            <FaFilter className="text-green-800" />
            <select
              value={referralFilter}
              onChange={(e) => setReferralFilter(e.target.value)}
              className="rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 text-sm"
            >
              <option value="">All Referrals</option>
              <option value="Direct">Direct</option>
              {referrals.map(referral => (
                <option key={referral.id} value={referral.code}>
                  {referral.code}
                </option>
              ))}
            </select>
          </div>

          {(startDate || endDate || referralFilter) && (
            <button
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setReferralFilter('');
              }}
              className="text-sm text-green-600 hover:text-green-800 px-2 py-1 rounded hover:bg-gray-100"
            >
              Clear Filters
            </button>
          )}
        </div>
      </div>

      {loading ? ( 
        <Loader />
      ) : (
        <div className="shadow-md rounded-lg">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-green-800 text-white">
              <tr>
                <th className="px-3 py-2 text-left text-sm">No</th>
                <th className="px-3 py-2 text-left text-sm">Name</th>
                <th className="px-3 py-2 text-left text-sm">Email</th>
                <th className="px-3 py-2 text-left text-sm">Application Date</th>
                <th className="px-3 py-2 text-left text-sm">Phone</th>
                <th className="px-3 py-2 text-left text-sm">Referral</th>
                <th className="px-3 py-2 text-left text-sm">Message</th>
                <th className="px-3 py-2 text-left text-sm">Travelers</th>
                <th className="px-3 py-2 text-left text-sm">Tour Package</th>
                <th className="px-3 py-2 text-left text-sm">Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredReservations.length > 0 ? (
                filteredReservations.map((reservation, index) => (
                  <tr key={reservation.reservation_id} className="border-t border-gray-200">
                    <td className="px-3 py-2 text-sm">{index + 1}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_name}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_email}</td>
                    <td className="px-3 py-2 text-sm">{new Date(reservation.reservation_date).toLocaleDateString()}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_phone}</td>
                    <td className="px-3 py-2 text-sm">{getReferralName(reservation.referral_id)}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_message}</td>
                    <td className="px-3 py-2 text-sm">{reservation.people}</td>
                    <td className="px-3 py-2 text-sm">{getPackageTitle(reservation.package_id)}</td>
                    <td className="px-3 py-2 flex justify-between text-sm">
                      {reservation.reservation_status === 'accepted' || reservation.reservation_status === 'rejected' ? (
                        <span className={`font-bold ${reservation.reservation_status === 'accepted' ? 'text-green-600' : 'text-red-600'}`}>
                          {reservation.reservation_status.charAt(0).toUpperCase() + reservation.reservation_status.slice(1)}
                        </span>
                      ) : (
                        <>
                          <button className="text-green-600 hover:text-green-500" onClick={() => handleAccept(reservation.reservation_id, reservation.reservation_email)}>
                            <FaCheck />
                          </button>
                          <button className="text-gray-600 hover:text-red-500" onClick={() => handleReject(reservation.reservation_id)}>
                            <FaTimes />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center px-3 py-2 border-t border-gray-200 text-sm">
                    No reservations found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReservedTickets;
