import React, { useState, useRef, useEffect } from 'react';

const MultiSelectDropdown = ({ 
  options, 
  selectedOptions, 
  onChange, 
  label,
  allowCustom = false,
  placeholder = "Select options"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customInput, setCustomInput] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (value) => {
    let newSelected;
    if (selectedOptions.includes(value)) {
      newSelected = selectedOptions.filter(item => item !== value);
    } else {
      newSelected = [...selectedOptions, value];
    }
    onChange(newSelected);
  };

  const handleCustomInputKeyDown = (e) => {
    if (e.key === 'Enter' && customInput.trim()) {
      e.preventDefault();
      const newValue = customInput.trim();
      if (!selectedOptions.includes(newValue)) {
        onChange([...selectedOptions, newValue]);
      }
      setCustomInput('');
    }
  };

  const removeOption = (optionToRemove) => {
    const newSelected = selectedOptions.filter(option => option !== optionToRemove);
    onChange(newSelected);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {label && <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>}
      <div
        className="border border-gray-300 rounded-md p-2 min-h-[38px] cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-wrap gap-1">
          {selectedOptions.map((option, index) => (
            <span
              key={index}
              className="bg-green-100 text-green-800 px-2 py-1 rounded-md text-sm flex items-center"
            >
              {option}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeOption(option);
                }}
                className="ml-1 text-green-600 hover:text-green-800"
              >
                ×
              </button>
            </span>
          ))}
          {selectedOptions.length === 0 && (
            <span className="text-gray-500">{placeholder}</span>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          {allowCustom && (
            <div className="p-2 border-b">
              <input
                type="text"
                value={customInput}
                onChange={(e) => setCustomInput(e.target.value)}
                onKeyDown={handleCustomInputKeyDown}
                className="w-full border border-gray-300 rounded-md p-1 text-sm"
                placeholder="Type and press Enter to add custom attraction"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}
          <div className="max-h-60 overflow-y-auto">
            {options.map((option, index) => (
              <div
                key={index}
                className={`p-2 cursor-pointer hover:bg-gray-100 ${
                  selectedOptions.includes(option.value) ? 'bg-gray-50' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOptionClick(option.value);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
