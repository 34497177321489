import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';

const AddActivity = ({ onClose, onAddActivity }) => {
  const [name, setName] = useState('');
  const [order, setOrder] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const api_url = `${API_URL}/activity/CreateActivity`;
  const upload_url = `${API_URL}/upload`;

  // Function to upload image
  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const token = getToken();
      const response = await axios.post(upload_url, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      
      return response.data.file_name;  
    } catch (err) {
      throw new Error('Image upload failed');
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const token = getToken();
  
      let uploadedImageURL = '';
      if (imageFile) {
        uploadedImageURL = await uploadImage();
        console.log('Uploaded Image URL:', uploadedImageURL); // Debug line
      }
  
      const formData = {
        activity_name: name,
        activity_order: order,
        activity_image: uploadedImageURL || imageURL,
      };
  
      console.log('Form Data:', formData); // Debug line
  
      const response = await axios.post(api_url, formData, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      console.log('API Response:', response); // Debug line
  
      if (response.status === 200) {
        setName('');
        setOrder('');
        setImageFile(null);
        setImageURL('');
        onAddActivity(response.data);
        onClose();
      } else {
        throw new Error('Failed to add activity');
      }
    } catch (error) {
      setError('Failed to add activity. Please try again.');
      console.error('Error adding activity:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };
  
  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-96">
        <h3 className="text-lg font-bold mb-4">Add Activity</h3>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="name">
              Activity Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="order">
              Activity Order
            </label>
            <input
              type="number"
              id="order"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2" htmlFor="image">
              Activity Image
            </label>
            <input
              type="file"
              id="image"
              onChange={handleImageChange}
              className="w-full px-3 py-2 border rounded-lg"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-400 text-white px-4 py-2 rounded-lg mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-green-800 text-white px-4 py-2 rounded-lg"
              disabled={loading}
            >
              {loading ? 'Adding...' : 'Add Activity'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddActivity;
