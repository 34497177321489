import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './screens/DashboardPage';
import ReservedTicketsPage from './screens/Reservations';
import CustomBookingsPage from './screens/Custom';
import Users from './screens/users';
import AccountSettings from './screens/AccountSetttings';
import { UserProvider } from './components/UserContext';
import Tours from './screens/ToursPage';
import SignIn from './screens/SignInPage';
import ContactUs from './screens/ContactUs';
import TourDetails from './components/TourDetails';
import { ImageProvider } from './components/ImageContext';
import EditTourForm from './components/EditTour';
import Layout from './components/Layout'; 
import LocationsPage from './screens/LocationsPage';
import SettingsPage from './screens/SettingsPage';
import ArticlesPage from './pages/ArticlesPage';
import SubscriptionsPage from './pages/SubscriptionsPage';
import ReviewsPage from './pages/ReviewsPage';
import Referrals from './screens/Referrals';

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <Router>
        <ImageProvider>
          <UserProvider>
            <Routes>
              <Route path="/" element={<SignIn />} />
              <Route
                path="/dashboard"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path="/bookings/reserved"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <ReservedTicketsPage />
                  </Layout>
                }
              />
              <Route
                path="/bookings/custom"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <CustomBookingsPage />
                  </Layout>
                }
              />
              <Route
                path="/tours"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Tours />
                  </Layout>
                }
              />
              <Route
                path="/tours/:tourId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <TourDetails />
                  </Layout>
                }
              />
              <Route
                path="/tour-details/:packageId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <TourDetails />
                  </Layout>
                }
              />
              <Route
                path="/edit-tour/:packageId"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <EditTourForm />
                  </Layout>
                }
              />
              <Route
                path="/contact"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <ContactUs />
                  </Layout>
                }
              />
              <Route
                path="/users"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Users />
                  </Layout>
                }
              />
              <Route
                path="/locations"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <LocationsPage />
                  </Layout>
                }
              />
              <Route
                path="/account"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <AccountSettings />
                  </Layout>
                }
              />
              <Route
                path="/settings"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <SettingsPage />
                  </Layout>
                }
              />
              <Route path="/signin" element={<SignIn />} />
              <Route
                path="/articles"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <ArticlesPage />
                  </Layout>
                }
              />
              <Route
                path="/subscriptions"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <SubscriptionsPage />
                  </Layout>
                }
              />
              <Route
                path="/reviews"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <ReviewsPage />
                  </Layout>
                }
                />
                <Route
                path="/referrals"
                element={
                  <Layout isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
                    <Referrals />
                  </Layout>
                }
              />
            </Routes>
          </UserProvider>
        </ImageProvider>
      </Router>
    </div>
  );
}

export default App;
