import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { FaStar } from 'react-icons/fa';
import toast from 'react-hot-toast';

const ReviewForm = ({ review, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    names: '',
    title: '',
    rating: 5,
    comment: '',
    id: null
  });
  const [error, setError] = useState('');
  const [hoveredRating, setHoveredRating] = useState(null);

  useEffect(() => {
    if (review) {
      setFormData({
        names: review.names,
        title: review.title,
        rating: review.rating,
        comment: review.comment,
        id: review.id
      });
    }
  }, [review]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      const formDataToSend = new FormData();
      formDataToSend.append('names', formData.names);
      formDataToSend.append('title', formData.title);
      formDataToSend.append('rating', formData.rating);
      formDataToSend.append('comment', formData.comment);

      let response;
      if (review) {
        // Update existing review
        formDataToSend.append('id', formData.id);
        response = await axios.post(
          `${API_URL}/reviews/updateReview`,
          formDataToSend,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
      } else {
        // Create new review
        response = await axios.post(
          `${API_URL}/reviews/createReview`,
          formDataToSend,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
      }

      if (response.data.status === 'success') {
        toast.success(response.data.message);
        onSave();
      }
    } catch (error) {
      console.error('Error saving review:', error);
      setError(error.response?.data?.message || 'Error saving review');
      toast.error('Failed to save review');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          value={formData.names}
          onChange={(e) => setFormData({ ...formData, names: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Rating</label>
        <div className="flex items-center mt-1">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              onClick={() => setFormData({ ...formData, rating: star })}
              onMouseEnter={() => setHoveredRating(star)}
              onMouseLeave={() => setHoveredRating(null)}
              className="p-1"
            >
              <FaStar
                className={`text-2xl ${
                  (hoveredRating || formData.rating) >= star
                    ? 'text-yellow-400'
                    : 'text-gray-300'
                }`}
              />
            </button>
          ))}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Comment</label>
        <textarea
          value={formData.comment}
          onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      {error && <div className="text-red-600">{error}</div>}

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
        >
          {review ? 'Update' : 'Add'} Review
        </button>
      </div>
    </form>
  );
};

export default ReviewForm; 