import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { toast } from 'react-hot-toast';

const ArticleForm = ({ article, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    article_title: '',
    article_content: '',
    article_author: '',
    article_image: null,
    article_id: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchArticleDetails = async () => {
      if (article?.article_id) {
        setLoading(true);
        try {
          const token = getToken();
          const response = await axios.get(
            `${API_URL}/article/getArticleById/${article.article_id}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            }
          );

          if (response.data.status === 'success') {
            const articleData = response.data.data;
            setFormData({
              article_title: articleData.article_title,
              article_content: articleData.article_content,
              article_author: articleData.article_author,
              article_image: null,
              article_id: articleData.article_id
            });
          }
        } catch (error) {
          console.error('Error fetching article details:', error);
          toast.error('Failed to fetch article details');
          setError('Failed to fetch article details');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchArticleDetails();
  }, [article]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const token = getToken();
      const formDataToSend = new FormData();

      formDataToSend.append('article_title', formData.article_title);
      formDataToSend.append('article_content', formData.article_content);
      formDataToSend.append('article_author', formData.article_author);
      formDataToSend.append('article_id', formData.article_id);

      if (formData.article_image instanceof File) {
        formDataToSend.append('article_image', formData.article_image);
      }

      // Log the data being sent
      console.log('Sending update with data:', {
        article_id: formData.article_id,
        title: formData.article_title,
        content: formData.article_content,
        author: formData.article_author
      });

      const response = await axios.post(
        `${API_URL}/article/updateArticle`,
        formDataToSend,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.data.status === 'success') {
        toast.success('Article updated successfully');
        onSave();
      }
    } catch (error) {
      console.error('Error updating article:', error);
      const errorMessage = error.response?.data?.message || 'Failed to update article';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading article details...</div>;
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-2 rounded-md text-sm">
          {error}
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          value={formData.article_title}
          onChange={(e) => setFormData({ ...formData, article_title: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Content</label>
        <textarea
          value={formData.article_content}
          onChange={(e) => setFormData({ ...formData, article_content: e.target.value })}
          rows={4}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Author</label>
        <input
          type="text"
          value={formData.article_author}
          onChange={(e) => setFormData({ ...formData, article_author: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Image</label>
        <input
          type="file"
          onChange={(e) => setFormData({ ...formData, article_image: e.target.files[0] })}
          className="mt-1 block w-full"
          accept="image/*"
        />
      </div>

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
        >
          Update Article
        </button>
      </div>
    </form>
  );
};

export default ArticleForm; 