import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaUsers, FaMap, FaCog, FaUser, FaInfo, FaBars, FaChevronDown, FaChevronUp, FaCompass, FaNewspaper, FaEnvelope, FaStar } from 'react-icons/fa';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isBookingsOpen, setBookingsOpen] = useState(false);
  const [isContentOpen, setContentOpen] = useState(false);

  return (
    <>
      <button onClick={toggleSidebar} className="fixed top-4 left-4 z-20 p-2 text-white md:hidden ">
        <FaBars className="text-2xl" />
      </button>
      <div className={`fixed top-0 left-0 bg-gradient-to-bl from-white to-gray-200  w-64 min-h-screen z-10 transition-transform duration-300 border-r border-gray-200 ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}>
        <div className="py-6 px-5 flex flex-col h-full">
          <div className="text-2xl text-green-800 pb-8 text-center" style={{ fontFamily: 'Impact, sans-serif' }}>
            SONG & STARK TOURS
          </div>
          <ul className="space-y-2 flex-grow">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-lg transition-colors ${
                    isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                  }`
                }
              >
                <FaTachometerAlt className="text-lg mr-3" /> Dashboard
              </NavLink>
            </li>
            <li>
              <div
                className="flex items-center text-sm p-3 rounded-lg cursor-pointer transition-colors hover:bg-gray-100"
                onClick={() => setBookingsOpen(!isBookingsOpen)}
              >
                <FaUsers className="text-lg mr-3" /> Bookings
                {isBookingsOpen ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
              </div>
              {isBookingsOpen && (
                <ul className="pl-6 space-y-2">
                  <li>
                    <NavLink
                      to="/bookings/reserved"
                      className={({ isActive }) =>
                        `flex items-center text-sm p-3 rounded-lg transition-colors ${
                          isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                        }`
                      }
                    >
                      Reservations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/bookings/custom"
                      className={({ isActive }) =>
                        `flex items-center text-sm p-3 rounded-lg transition-colors ${
                          isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                        }`
                      }
                    >
                      Custom Bookings
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink
                to="/tours"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-lg transition-colors ${
                    isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                  }`
                }
              >
                <FaMap className="text-lg mr-3" /> Tour Packages
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-lg transition-colors ${
                    isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                  }`
                }
              >
                <FaInfo className="text-lg mr-3" /> Contacts Inquiries
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-lg transition-colors ${
                    isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                  }`
                }
              >
                <FaUser className="text-lg mr-3" /> Users
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/locations"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-md transition-colors ${
                    isActive ? 'bg-green-800 text-white' : ' hover:bg-gray-100'
                  }`
                }
              >
                <FaCompass className="text-base mr-2" /> Locations
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  `flex items-center text-sm p-3 rounded-lg transition-colors ${
                    isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                  }`
                }
              >
                <FaCog className="text-lg mr-3" /> Settings
              </NavLink>
            </li>
            <li>
              <div
                className="flex items-center text-sm p-3 rounded-lg cursor-pointer transition-colors hover:bg-gray-100"
                onClick={() => setContentOpen(!isContentOpen)}
              >
                <FaNewspaper className="text-lg mr-3" /> Blogs
                {isContentOpen ? <FaChevronUp className="ml-auto" /> : <FaChevronDown className="ml-auto" />}
              </div>
              {isContentOpen && (
                <ul className="pl-6 space-y-2">
                                    <li>
                    <NavLink
                      to="/reviews"
                      className={({ isActive }) =>
                        `flex items-center text-sm p-3 rounded-lg transition-colors ${
                          isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                        }`
                      }
                    >
                      <FaStar className="text-lg mr-3" /> Reviews
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/articles"
                      className={({ isActive }) =>
                        `flex items-center text-sm p-3 rounded-lg transition-colors ${
                          isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                        }`
                      }
                    >
                      <FaNewspaper className="text-lg mr-3" /> Articles
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/subscriptions"
                      className={({ isActive }) =>
                        `flex items-center text-sm p-3 rounded-lg transition-colors ${
                          isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                        }`
                      }
                    >
                      <FaEnvelope className="text-lg mr-3" /> Subscriptions
                    </NavLink>
                  </li>

                </ul>
              )}
            </li>
            <li>
            <NavLink
              to="/referrals"
              className={({ isActive }) =>
                `flex items-center text-sm p-3 rounded-lg transition-colors ${
                  isActive ? 'bg-green-800 text-white' : 'hover:bg-gray-100'
                }`
              }
            >
              <FaUsers className="text-lg mr-3" /> Referrals
            </NavLink>
          </li>
          </ul>
        </div>
      </div>
      {isOpen && <div className="fixed inset-0 bg-black opacity-25 z-0" onClick={toggleSidebar} />}
    </>
  );
};

export default Sidebar;
